/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ReactGA from "react-ga"
// import ReactPixel from 'react-facebook-pixel';

// function getCookie(cname) {
//   var name = cname + '=';
//   var decodedCookie = decodeURIComponent(document.cookie);
//   var ca = decodedCookie.split(';');
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) === 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return '';
// }

function SEO({ description, lang, meta, title, image, keywords }) {
  useEffect(() => {
    // let onboarding = getCookie('onboarding');
    // if (onboarding) {
    //   if (process.env.NODE_ENV !== "development") {
    //     ReactGA.initialize("UA-625331-61");
    //     ReactGA.pageview(window.location.pathname);
    //     // const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    //     // const options = {
    //     //   autoConfig: true, // set pixel's autoConfig
    //     //   debug: false, // enable logs
    //     // };
    //     // ReactPixel.init("pixelid", advancedMatching, options);
    //     // ReactPixel.pageView();
    //   }
    // }
    if (process.env.NODE_ENV !== "development") {
      ReactGA.initialize("UA-625331-61")
      ReactGA.pageview(window.location.pathname)
      // const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
      // const options = {
      //   autoConfig: true, // set pixel's autoConfig
      //   debug: false, // enable logs
      // };
      // ReactPixel.init("pixelid", advancedMatching, options);
      // ReactPixel.pageView();
    }
  }, [])

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
          }
        }
      }
    `
  )
  const metaDescription =
    description === "" ? site.siteMetadata.description : description
  const metaTitle = title === "" ? site.siteMetadata.title : title
  const metaKeywords =
    keywords === "" ? title + ", " + site.siteMetadata.keywords : keywords
  image = image === "" ? "/og-image.png" : image
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={title === "" ? `%s` : `%s | ${site.siteMetadata.title}`}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: site.siteMetadata.siteUrl + image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="search"
        type="application/opensearchdescription+xml"
        title={site.siteMetadata.title}
        href={
          process.env.NODE_ENV === "development"
            ? "http://localhost:8888/opensearchdev.xml"
            : "https://plan.nc/opensearch.xml"
        }
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  keywords: ``,
  image: `/og-image.png`,
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
